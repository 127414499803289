export default {
  "Header": {
    "aboutUs": "Hakkımızda",
    "products": "Ürünler",
    "solutions": "Çözümler",
    "career": "Kariyer",
    "career_detail": "Kariyer Detayları",
    "contactUs": "Bize Ulaşın"
},
  "Title": {
    "aboutUs": "Hakkımızda",
    "products": "Ürünler",
    "solutions": "Çözümler",
    "career": "Kariyer",
    "career_detail": "Kariyer Detayları",
    "contactUs": "Bize Ulaşın"
  },
  "Footer": {
    "footerText":"Araştırma | Çözümleme | Tasarlama | Geliştirme | Ürünleştirme | İşletme ve Bakım",
    "copyRight": "© Copyright 2020 ete technology<0>®</0>. Tüm hakları saklıdır.",
    "products":"Ürünler",
    "solutions": "Çözümler",
    "aboutUs":"Hakkımızda",
    "news":"Haber Odası",
    "contactUs":"Bize Ulaşın",
    "clarification":"Kişisel Veriler",
    "followUs":"Bizi Takip Edin"
  },
"NewsCard": {
  "readMore": "daha fazla"
},
"NewsModal": {
  "description": '"2019 Yılı En Fazla FSMH Başvurusu Yapan Ar-Ge Firması” ödülünün sahibi olduk!',
  "descriptionMorePartOne": "Teknopark İzmir Yüksek Teknoloji Ödülleri kapsamında, “2019 Yılı En Fazla FSMH Başvurusu Yapan Ar-Ge Firması” ödülünün sahibi olduk.",
  "descriptionMorePartTwo": "175 Ar-Ge firması arasından tarafımıza layık görülen gurur verici bu ödül, İYTE Rektörü Sn. Prof. Dr. Yusuf Baran tarafından 16.11.2020 tarihinde İYTE Gösteri Merkezi’nde düzenlenen törenle şirketimize takdim edildi.",
  "descriptionMorePartThree": "ete technology<0>®</0> ailesi olarak, Teknopark İzmir A.Ş. Yönetim Kurulu'na ve katkılarından dolayı tüm takım arkadaşlarımıza teşekkür ederiz.",
  "readMore": "DAHA FAZLA"
},
  "Home": {
    "mainDescriptionTitle": "Gelecek. Değişim. Dönüşüm",
    "mainDescription": "2016 yılından bu yana, yüksek teknoloji tabanlı, yenilikçi ve benzersiz ürünler geliştiriyoruz.",
    "aboutUsButton":"HAKKIMIZDA",
    "eteSolutionDescriptionPartOne": "ete technology<0>®</0> emniyet, güvenlik, savunma ve sağlık alanlarında öğrenim, eğitim ve deney teknolojileri geliştiren bir firmadır. Patentli teknolojilerimizi kullanarak geliştirdiğimiz ürünlerimizi, kullanıcılarımıza kendi altyapıları üzerine kurup lisanslayarak veya hizmet olarak yazılım ve platform formatlarında sunuyoruz.",
    "eteSolutionDescriptionPartTwo": "ete technology<0>®</0>, ihtiyaç duyulması halinde ürünlerini kullanıcılarının ihtiyaçlarını tam karşılayacak şekilde uyumlayabilmektedir. Ürünlerimizi de içeren çözümleri bir yetenek paketi olarak verip, talep edilen yer ve zamanda işletme ve bakım desteği de sağlıyoruz.",
    "eteSolutionDescriptionPartThree": "Uzmanlık alanlarımız çok kanallı insan makine etkileşimi, büyük veri ve enformasyon birleştirme, açıklanabilir yapay zeka, artırılmış zeka, hiperotomasyon, mekatronik, modelleme, simülasyon, ciddi oyunlar, veri merkezleri ve siber güvenlik.",
    "eteSolutionMainNewsPartOne": "",
		"eteSolutionMainNewsPartTwo": "",
		"eteSolutionMainNewsPartThree": "",
    "contactUsDescription":"Gerçekleştirecek bir fikriniz varsa!",
    "contactUsButton":"BAĞLANTI KURUN",
    "products":"Ürünler",
    "solutions":"Çözümler",
    "ephesus":"Müşterek Harp ve Eğitim Merkezleri",
    "ephesusDescription":"ete technology<0>®</0> müşterek harp ve eğitim merkezleri üretmektedir. Merkezin kavramsal tanımını yapıp etkinliğini kanıtladıktan sonra tasarlayıp, doktrini, tesisleri, donanımı, yazılımı ve eğitimini içerecek şekilde bir yetenek paketi olarak kullanıcılarımıza sunuyoruz. ete technology<0>®</0>, bir müşterek harp ve eğitim merkezinin ihtiyaç duyduğu bütün yazılımları ürün portföyünde bulundurmakta ve bu yazılımları kullanıcılarının ihtiyaçlarına göre uyarlayabilmektedir.",
    "modelingAndSimulation":"Müşterek İstihbarat, İzleme ve Keşif Merkezleri",
    "modelingAndSimulationDescription":"ete technology<0>®</0> müşterek istihbarat izleme ve keşif (ISR) merkezleri üretmektedir. Merkezin kavramsal tanımını yapıp etkinliğini kanıtladıktan sonra tasarlayıp, doktrini, tesisleri, donanımı, yazılımı ve eğitimini içerecek şekilde bir yetenek paketi olarak kullanıcılarımıza sunuyoruz. ete technology<0>®</0>, bir müşterek ISR merkezinin ihtiyaç duyduğu bütün yazılımları ürün portföyünde bulundurmakta ve bu yazılımları kullanıcılarının ihtiyaçlarına göre uyarlayabilmektedir. ",
    "dataServices":"Veri Servisleri",
    "dataServicesDescription":"ete technology<0>®</0>, veri mühendisliği, veri yönetimi, veri analitiği, veri güvenliği ve kişisel verilerin korunması dahil olmak üzere veri bilimi üzerinde çalışan bir araştırma ve geliştirme şirketidir. Büyük veri, makine öğrenimi, veri çözümleme ve veri madenciliği ile ilgili uzmanlık ve tescilli araçlar geliştirdik. ete technology<0>®</0>, veri analitiği konusundaki uzmanlığını ve araçlarını, müşterilerinin ihtiyaçlarına göre özelleştirilmiş destek sağlamak için kullanmaktadır.",
    "eteTrainings": "ETE3 Eğitimler",
    "eteTrainingsDescription":"ete technology<0>®</0>, ETE3 faaliyetlerinin planlanması, hazırlanması ve yürütülmesi üzerine uluslararası tanınmışlığı olan kurslar düzenlemektedir. Kursların tam listesini ve içeriğini <1>burada</1> bulabilirsiniz.",
    "eteServices": "ETE3 Servisler",
    "eteServicesDescription":"ete technology<0>®</0>, öğrenim, eğitim, tatbikatlar, deneyler ve değerlendirmeleri (ETE3) desteklemek için hizmetler sunmaktadır.",
  },
  "AboutUs": {
    "ceoDescriptionTitle":"Gelecek. Değişim. Dönüşüm",
    "ceoDescription": "ETE Teknoloji AŞ, 2016 yılından bu yana güvenlik, emniyet ve savunma alanlarında kullanılmak üzere, alanında yüksek birikimi olan akademisyen ve mühendis kadrosuyla AR-GE faaliyetlerine devam etmekte, buna bağlı olarak yüksek teknoloji tabanlı ve her biri kendi segmentinde benzersiz ve yenilikçi ürünler geliştirmektedir.\n\nŞirketimiz, uluslararası standartlarla tam uyumlu iş süreçleri ve patentli ürün portföyüyle, müşterilerine yüksek performans ve kalite odaklı çözümler sunmayı ve küresel pazarlarda öncü ve bilinir olmayı hedeflemektedir. Müşteri memnuniyetinin yanı sıra çalışan memnuniyetini de gözeterek tüm paydaşlara katma değer sağlamak da öncelikleri arasındadır.",
    "team":"ete technology<0>®</0>",
    "teamDescription":"Esas gücünü, yaratıcı, tutkulu ve motivasyonu yüksek zihinlerden alıyor!",
    "founderAndChairman":"Başkan ve Kurucu",
    "ceo":"Genel Müdür",
    "coo": "",
    "chiefTechnologyOfficer": "Genel Teknoloji Yöneticisi",
    "qualityAssurance":"Kalite Kontrol Mühendisi - Kalite Kontrol ve Ürünleştirme Departmanı",
    "humanResources":"Uzman - İnsan Kaynakları Departmanı",
    "bigDataTeamLeader":"Takım Lideri - Büyük Veri ve Enformasyon Bütünleştirme",
    "hmiTeamLeader":"Takım Lideri - Önyüz ve İnsan Makina Etkileşimi",
    "aiTeamLeader":"Takım Lideri - Yapay Zeka ve Makina Öğrenmesi",
    "msTeamLeader":"Takım Lideri -  Modelleme, Simülasyon ve Oyun",
    "cyberSecurityTeamLeader":"Takım Lideri - Veri Merkezi ve Siber Güvenlik",
    "news":"Haber Odası",
    "firstNew":"Nisan ayı sonu itibariyle Melez Çok Alanlı Operasyon ve Taktik Simülatörü’müzün (hymots<0>®</0>) prototipini geliştirdik!",
    "secondNew":"Savunma Sanayii Başkanlığı Ar-Ge ve Teknoloji Yönetimi faaliyetleri kapsamında 21 Nisan 2020 tarihinde...",
    "thirdNew":"Savunma Sanayi Başkanlığı’nın 2020 yılı için planladığı 17 ARGE projesinden biri olan HAMLE projesini 13 Ocak 2020...",
    "fourthNew":'"2019 Yılı En Fazla FSMH Başvurusu Yapan Ar-Ge Firması” ödülünün sahibi olduk!',
    "nginrNew": "nginr<0>®</0>, enformasyon bütünleştirme konusunda her geçen gün daha geniş alanlarda kullanılmaya devam ediyor.",
    "hamleNew": `Savunma Sanayii Başkanlığı bünyesinde gerçekleştirilen AR-GE Projeleri kapsamında, alt yüklenicisi olduğumuz "Hareket Tarzı Geliştirilen Yapay Zeka Komutan Asistanı Geliştirme (HAMLE) Projesi’nin” ilk entegrasyonunu Aselsan A.Ş. ile birlikte 3 Şubat 2021’de başarıyla tamamladık.`,
    "hamleNew2": `Savunma Sanayii Başkanlığı bünyesinde gerçekleştirilen AR-GE Projeleri kapsamında, alt yüklenicisi olduğumuz "Hareket Tarzı Geliştiren Yapay Zekâlı Komutan Asistanı (HAMLE) Projesi’nin” ikinci entegrasyonunu Aselsan A.Ş. ile 25 Şubat 2021 tarihinde başarıyla tamamladık.`,
    "fifthYear":"Şirketimizin 5. kuruluş yıldönümünde çalışanlarımızı inançlı ve başarı odaklı çalışmalarından dolayı tebrik eder, iş ortaklarımıza bize duydukları güven için teşekkür ederiz. Bu sorumluluğun bilinciyle bilimsel düşünmeye, teknoloji üretmeye ve paylaşmaya devam edeceğiz. Nice yıllara!\nTülin ÇAYIRCI - Genel Müdür",
    "hymotsg1": "hymots<0>®</0>g, kullanıcı ile buluştu: 2018 yılında geliştirmeye başladığımız hymots<0>®</0>g, ilk kullanıcısı tarafından bir jeostratejik analiz görevinde başarı ile kullanılarak en yüksek teknoloji hazırlık seviyesine ulaştı. Projede emeği geçen herkese teşekkürler.",
    "nginrNews1": "Bütünleşik güvenlik ve emniyet sistemimiz olan nginr<0>®</0>s teknoloji hazırlık seviyesi TRL9 ile kullanıcılarımızın hizmetindedir.",
    "orientation1": "Yılın ilk eğitim toplantısını tamamladık.",
    "hackathonNews1": "İş birliği içinde olduğumuz HACKATHON etkinliğini organize eden Teknopark İzmir ve ITÜ Arı Teknokent'e teşekkür eder, tüm takımlara başarılar dileriz.",
    "summerActiveNews1": "ete technology<0>®</0> ailesi olarak 2022 yaz etkinliğimizi Urla’da bulunan Titus Feronia’da gerçekleştirdik. Şehir hayatından uzak, doğa ile iç içe, eğlence ve keyif dolu bir etkinliği de arkamızda bıraktık.",
    "havelsanNews1": "IDEF’2023 esnasında Havelsan A.Ş. ile işbirliği protokolü imzaladık.",
    "ca2x2News1": "We are delighted to be part of the 18th NATO CA2X2 (Computer Assisted Analysis, Exercise, Experimentation) Forum 2023 (Rome, from 3-5 October.) organized by the NATO Modelling and Simulation Centre of Excellence.",
    "samaFatNews": "Software and Training Services on Information and Knowledge Management (IKM) projemizin Fabrika Kabul Testi (FAT – SAMA 0.0.0.0) başarıyla tamamlandı.\n\nEmeği geçen tüm ekip arkadaşlarımızı tebrik ederiz.",
    "foeNews1": "The Future Operating Environment Wargame of NATO was supported successfully with etecube® and hymots<0>®</0> in Paris on 27th March, 2024.",
		"foeNews2": "Our products etecube<0>®</0>, hymots<0>®</0> and nginr<0>®</0> are participating in the next NATO Federation on HLA 4.0 and NATO Education Training Network FOM within NATO Technical Activity MSG 223.",
    "orientation2": `"Yönetim Akademisi" eğitim sürecinin önemli bir aşaması olan "Yönetimin Temelleri" modülünü başarıyla tamamladık! 🎉📚  Edindiğimiz bilgilerle şirketimizin başarısına katkı sağlamak için motive ve kararlı bir şekilde ilerlemeye devam edeceğiz.`,
    "havelsanTskNews1": "27-28 Mayıs tarihlerinde, HAVELSAN A.Ş. tesislerinde Türk Silahlı Kuvvetleri (TSK) ve Milli Savunma Bakanlığı (MSB) personeline ürünlerimizin tanıtımı gerçekleştirildi.",
    "viewAllNews":"Tümünü Görüntüle"
  },
  "JobApply": {
    "softwareEngineerTitle": "Yazılım Mühendisi",
		"partTimeTitle": "Yarı Zamanlı",
    "succesfullApply":"Başvurunuz gönderildi!",
    "succesfullApplyDescription":"Başvurunuz değerlendirildikten sonra sizinle iletişime geçilecektir.",
    "homeButton":"ANASAYFA",
    "firstName":"Ad*",
    "lastName":"Soyad*",
    "email":"E-posta*",
    "dateOfBirth":"Doğum tarihi* (gün/ay/yıl)",
    "city":"Şehir*",
    "personalWebsite":"Kişisel Website",
    "twitter":"Twitter",
    "linkedIn":"LinkedIn",
    "facebook":"Facebook",
    "education":"Eğitim*",
    "experience":"Deneyim*",
    "portfolio":"Dosyanızı buraya sürükleyin",
    "orSelectFile":"veya dosya seçin",
    "isRequired":"Bu alanın doldurulması zorunludur.",
    "invalidEmail":"Geçersiz E-posta adresi.",
    "invalidDate":"Geçersiz format (gün/ay/yıl).",
    "invalidUrl":"Geçersiz URL.",
    "apply":"BAŞVUR"
},
"ETECube":{
  "etecubeBooklet":"Please click <1>here</1> to see the etecube<0>®</0> booklet."
},
"Nginr": {
  "nginrMainDescription": "Büyük veriden enformasyonu çekmek ve çekilen enformasyonun anlamsal geçerlemesini yaptıktan sonra blokağı temelli bir bilgitabanında bütünleştirmek için geliştirilmiş bir bilgitabanı yönetim sistemidir.",
  "nginrDescriptionPartOne": "Tanıma ve birleştirme makinesi (nginr<0>®</0>), büyük veri analitiği ve enformasyon birleştirme aracıdır. Çok yüksek ölçeklenebilirliğe sahip otonom bir bilgitabanı yönetim sistemi olarak, akan büyük veriden eyleme geçirecek doğrulanmış bilgiyi üretmek için geliştirilmiştir.",
  "nginrDescriptionPartTwo": "Tek bir homojen veri kümesi, yerine çok sayıda heterojen veri kümeleri üzerinde artırılmış veri analitiği desteği sağlamaktadır. Eyleme geçirecek doğrulanmış bilgi yaklaşımımız, sadece insanların karar verme süreci için değil makinalar ve hiperotomasyon amacıyla da kullanılabilecek analitikler anlamına gelmektedir.",
  "nginrDescriptionPartThree":"nginr<0>®</0>, gerçek zamanlı ve devamlı istihbarat, güvenlik, veri bütünlüğünün korunma garantisi ve hesap sorulabilirlik gibi zorlu isterleri olan sistemlerin ihtiyaçlarını karşılamak için tasarlanmıştır. Açık kaynaktan akan büyük veriyi işleyerek enformasyonu otonom olarak çeken yapay zeka teknikleri ile donatılmıştır. Sistem paralel işlem tekniklerinden en üst düzeyde yararlanan güvenli ve dağıtık bir mimaridedir. Bulut bilişim ve hizmet olarak platform dahil bir çok mimaride çalışmaya uygundur.",
  "nginrDescriptionPartFour": "Please click <1>here</1> to see the nginr<0>®</0>s booklet.",
  "nginrDescriptionPartFive": "Please click <1>here</1> to see the nginr<0>®</0>m booklet."
},
"Hymots": {
  "hymotsMainDescription": "Sayısal ikizlere, ciddi oyunlara, deneylere ve eğitim çözümlerine olanak tanıyan mikro etmen temelli modelleme ve simülasyon platformudur.",
  "hymotsDescriptionPartOne": "Melez çok alanlı operasyon ve taktik simülatörü (hymots<0>®</0>) mikro etmen temelli bir askeri yapısal simülasyon sistemidir. Sistem çok yüksek ölçeklenebilirlikte, yüksek başarım ve sadakat ile çalışabilecek şekilde geliştirilmiştir. İşlemler en fazla düzeyde paralelleştirilmiş ve gelişmiş grafik işlem birimlerinin (GPU) yeteneklerinden en iyi şekilde yararlanılmıştır. Kara, deniz, hava, uzay ve siber alanlarda operasyon ve taktikleri simüle edebilmektedir. Melez ortamlar için bir jeostratejik oyun platformu da sağlamaktadır.",
  "hymotsDescriptionPartTwo": "Kullanıcılar iki veya üç boyutlu ekranlar kullanarak sistem ile etkileşebilmektedir. Arazi, binalar, hava, uzay havası, lojistik, insan ve topluluk davranışları modellenmektedir. Sistem bir masaüstü veya dizüstü bilgisayarda çalışabildiği gibi bulut bilişim, sanallaştırma gibi teknik ve kullanım şekillerine de izin vermektedir.",
  "hymotsDescriptionPartThree": "hymots<0>®</0>, tatbikat, eğitim ve askeri deney hedeflerinin analizi ve yönetilmesi, hedeflere ulaşma seviyesinin ölçülmesi, ana olaylar listelerinin hazırlanması, yönetilmesi ve simülasyon ile eş uyumlaması için araç ve birimlere de sahiptir.",
  "hymotsDescriptionPartFour": "ete technology<0>®</0>, askeri deneyler, harpoyunları ve tatbikatların planlanması, senaryoların hazırlanması, ana olaylar listelerinin hazırlanıp yönetilmesi konularında ehil uluslararası tanınmış personele sahiptir. Bu personel ile müşterilerinin tatbikat, deney ve harpoyunlarına destek olmakta ve eğitim desteği de sağlamaktadır.",
  "hymotsDescriptionPartFive":"hymots<0>®</0> ürün katalogunu <1>burada</1> bulabilirsiniz.",
},
"Arts" : {
  "artsMainTitle": "ARTS<0>®</0>: Yapay Zeka Destekli Tanıma Eğitmeni ve Kum Sandığı",
  "artsMainDescription": "ARTS<0>®</0>, ileri yapay zeka teknolojilerini kullanan üç boyutlu (3D) holografik görselleştirme donanımı ve yazılımıdır. Aşağıdaki modlardan biriyle kullanılabilir: tanıma eğitmeni veya holografik çoklu-görüntü kum sandığı. Ortak istihbarat, gözetleme ve keşif (JISR) eğitimi, ekipman/tesis tasarımı ve tanıtımı, taktik ve operasyonel seviyelerde harp oyunları ve tatbikatlar gibi çeşitli süreçleri desteklemek amacıyla tasarlanmıştır. Bilgisayar destekli harp oyunları ve tatbikatlar sırasında kullanıcı deneyimini geliştirmek için hymots<0>®</0>c ile sorunsuz bir şekilde entegre edilmiştir.",
  "artsSubTitleOne": "01. Ana Ürün Özellikleri",
  "artsSubTitleOneListOneTitle": "Tanıma Eğitmeni Modu",
  "artsSubTitleOneListOneItemOne": "3D görselleştirme",
  "artsSubTitleOneListOneItemTwo": "Eşzamanlı 360° görselleştirme",
  "artsSubTitleOneListOneItemThree": "Ses ve hareket tanıma",
  "artsSubTitleOneListOneItemFour": "Eğitim ve test modları",
  "artsSubTitleOneListTwoTitle": "Holografik Çoklu-Görüntü Kum Sandığı Modu",
  "artsSubTitleOneListTwoItemOne": "Hymots<0>®</0> ile kesintisiz entegrasyon",
  "artsSubTitleOneListTwoItemTwo": "3D görselleştirme",
  "artsSubTitleOneListTwoItemThree": "Eşzamanlı 360° görselleştirme",
  "artsSubTitleOneListTwoItemFour": "Ses ve hareket tanıma",
  "artsSubTitleOneListTwoItemFive": "Tek bir masa etrafında çok algılı harp oyunları",
  "artsSubTitleOneListTwoItemSix": "Çevik ve dinamik operasyonel görüntü manipülasyonu",
  "artsSubTitleTwo": "02. Ürün Bilgisi",
  "artsSubTitleOfSubTitleTwo": "ARTS, iki farklı modda kullanılabilir",
  "artsSubTitleTwoContentOne": "Tanıma Eğitmeni Modu, kullanıcılara ARTS kullanıcıları tarafından seçilen bir dizi muharebe sistemini tanımlamak için aktif bir öğrenme ve test ortamı sunar.",
  "artsSubTitleTwoContentTwo": "Çoklu-Görüntü Kum Sandığı Modu, bilgisayar destekli harp oyunları ve tatbikatları destekler. En fazla dört farklı tanınmış görüntü (gerçek durum, tanınmış dost birlik, tanınmış karşıt güç, lojistik resmi vb.) görüntü yüzeylerine yansıtılabilir. Böylece kullanıcılar birbirlerini görüp aynı ARTS’a bakıyor olsalar da farklı tanınmış görüntüleri görebilirler. Bu sayede, tek bir masa etrafında çok taraflı bir harp oyunu gerçekleştirilebilir.",
  "artsSubTitleThree": "02.1 Tanıma Eğitmeni Yazılımı",
  "artsSubTitleThreeBoldTextOne": "Dinamik Öğrenme",
  "artsSubTitleThreeBoldTextTwo": "Eğitim Modu",
  "artsSubTitleThreeBoldTextThree": "Test Modu",
  "artsSubTitleThreeBoldTextFour": "Kişiselleştirilmiş İlerleme",
  "artsSubTitleThreeBoldTextFive": "Performans Değerlendirme ve Geliştirme",
  "artsSubTitleThreeContentOne": "Holografik teknoloji, keşif ve istihbarat personelinin muharebe sistemleri ve tesislerinin tanınması konusunda eğitimini destekler. Kullanıcılar, bir nesnenin özellikleri hakkında daha derinlemesine bilgi edinebilirler.",
  "artsSubTitleThreeContentTwo": "Bu mod, kullanıcının öğrenme sürecine odaklanır. Zengin içerik, simülasyonlar ve etkileşimli görseller sunar. Eğitim alan kişiler, kendi hızlarında ilerleyebilir, öğrenme materyallerine erişebilir ve konuları derinlemesine inceleyebilirler.",
  "artsSubTitleThreeContentThree": "Test modu, öğrenmeyi pekiştirmek ve bilgiyi ölçmek için tasarlanmıştır. Kullanıcının muharebe sistemiyle ilgili sorular, simülasyonlar veya etkileşimli senaryolar sunarak anlayışlarını değerlendirir.",
  "artsSubTitleThreeContentFour": "Hem eğitim hem de test modları, kişiselleştirilmiş ve uyarlanabilir öğrenme deneyimleri sunar. Öğrenciler, güçlü ve zayıf yönlerini belirleyebilir, seçtikleri konulara odaklanabilir ve anlama eksikliklerini gidermek için özelleştirilmiş bir öğrenme planı oluşturabilirler.",
  "artsSubTitleThreeContentFive": "Test modu kullanıcı performansını değerlendirirken, eğitim modu bu değerlendirmelere dayanarak iyileştirmeler sağlar. Bu yaklaşım, kullanıcıların gelişime açık alanlarını belirlemelerine ve bilgi ve becerilerini daha etkili bir şekilde geliştirmeye odaklanmalarına yardımcı olur.",
  "artsSubTitleFour": "02.2 Çoklu-Görüntü Kum Sandığı Yazılımı",
  "artsSubTitleFourContentOne": "Görüntüler, hymots<0>®</0> tarafından üretilir. Kullanıcılar, simüle edilen sahneyi aynı yönden ve aynı yakınlaştırma seviyesinde görebilirler. Alternatif olarak, tanınmış görüntüler farklı yönler için projekte edilebilir. Ayrıca, görüntü yüzeylerindeki tanınmış görüntülerin senkronize edilmesi de mümkündür. Örneğin, dost birliklerin tanınmış görüntüyü bir yüzeye, karşıt kuvvetlerin tanınmış görüntüyü ise karşı ekrana zıt yönden projekte edilebilir.",
  "artsSubTitleFourContentTwo": "Tanınmış görüntüler üç boyutludur. Birimler, üç boyutlu askeri semboloji ikonları veya tanklar, insanlar ve topçu parçaları gibi varlıklarla gösterilebilir.",
  "artsSubTitleFive": "02.3 Donanımın Detayları",
  "artsSubTitleFiveListOneTitle": "Ana Donanım Bileşenleri",
  "artsSubTitleFiveListOneItemOne": "Projeksiyon Yüzeyi",
  "artsSubTitleFiveListOneItemTwo": "Çerçeve ve Ana Kart",
  "artsSubTitleFiveListOneItemThree": "Monitörler",
  "artsSubTitleFiveListOneItemFour": "Kameralar ve Mikrofon",
  "artsSubTitleFiveListOneItemFive": "İşlem Birimi",
  "artsSubTitleFiveListOneItemSix": "Yazılım ve 3D Modeller",
  "artsSubTitleFiveListTwoTitle": "Donanım Özellikleri",
  "artsSubTitleFiveListTwoItemOne": "Güç kaynağı: AC100-240 50/60Hz",
  "artsSubTitleFiveListTwoItemTwo": "Güç tüketimi (Aktif mod): 458W",
  "artsSubTitleFiveListTwoItemThree": "Güç tüketimi (Uyku modu): 283W",
  "artsSubTitleFiveListTwoItemFour": "Ürün boyutları",
  "artsSubTitleFiveListTwoItemFive": "Boyut (dış boyutlar): 160 x 160 x 80 cm",
  "artsSubTitleFiveListTwoItemSix": "Ürün ağırlığı: 55 kg",
  "artsSubTitleFiveListTwoItemSeven": "Çözünürlük (her bir görüntü yüzeyi için): 1.920 x 1.080",
  "artsModelsText": "Modeller",
  "artsSubTitleSix": "03. Ürün İçeriği",
  "artsSubTitleSixContent": "Donanım ve yazılım lisansı, donanım ve yazılım sorunları için beş yıllık garanti ile birlikte süresiz olarak verilir ve bir yıl boyunca yeni sürümleri içerir. Yıllık olarak yaklaşık 100 yeni 3D model piyasaya sürülmektedir. Bu modeller, hymots<0>®</0> veritabanına da eklenir. İlk yılın sonunda, yeni yazılım sürümleri ve modeller için lisansın yenilenmesi gerekmektedir."
},
"ContactUs": {
  "address":"Ofislerimiz",
  "mail":"E-posta",
  "phone":"Telefon",
  "fax":"Fax",
  "workingHours":"Çalışma Saatleri",
  "yourNamePlaceholder": "Adınız Soyadınız*",
  "yourMailPlaceholder": "E-posta adresiniz*",
  "yourMessagePlaceholder": "Mesajınız*",
  "isRequired":"Bu alanın doldurulması zorunludur.",
  "invalidEmail":"Geçersiz E-posta adresi.",
  "sendButton":"GÖNDER",
  "clarificationText": " 'ni okudum, kabul ediyorum.",
  "clarificationTextPopup": "Aydınlatma metni",
  "geziRehberi": "Yabancı misafirlerimiz için bölgemiz hakkında...",
},
"Career":{
  "introductionTitle":"Orijinal fikre ve yeteneğe her zaman değer veririz!",
  "introductionDescriptionOne": "· Kariyer hedeflerinize doğru bir adım daha atın.",
  "introductionDescriptionTwo":"· Özverili, yaratıcı ve dinamik insanlarla çalışın.",
  "introductionDescriptionThree": "· Gelişen ve değişen teknolojilere uyum sağlayın.",
  "introductionDescriptionFour":"· Yeteneğinizi keşfedin ve deneyimleyin.",
  "secondTitle": "Yüksek verimlilik için yetenekli ve yaratıcı takım arkadaşlarına ihtiyacımız var!",
  "jobDescription": "Orijinal fikre ve yeteneğe her zaman değer veririz. Eğer düşünen ve üreten bir ekibin parçası olmak istiyorsan, haydi tanışalım.",
  "internJobDescription":"Staj",
  "internDescription":"Öğrenim hayatının son dönemecine girdiysen ve teorik bilgilerini endüstride nasıl uygulayabileceğini keşfetmek istiyorsan ete technology<0>®</0> Staj Programı olanaklarından yararlanabilirsin.",
  "partTimeDescription":"ete technology<0>®</0>’de her yıl birçok üniversite öğrencisi projelerimizde aktif olarak rol alıyor, farklı çalışma konularında iş deneyimi kazanıyor.",
  "softwareEngineerDescription":"ete technology<0>®</0>, Teknopark İzmir Mühendislik Atölyesi için yeni takım arkadaşlarını arıyor!",
  "partTimeJobDescription":"Yarı Zamanlı Çalışma Modeli",
  "softwareEngineer":"Yazılım Mühendisi",
  "msApplication": "Yazılım Mühendisi",
  "msApplicationDescription": "Modelleme, Simülasyon ve Oyun Departmanımıza yeni takım arkadaşları arıyoruz!",
  "hmiApplication": "Web Uygulama Geliştirici",
  "hmiApplicationDescription": "Önyüz ve İnsan Makine Etkileşimi Departmanımıza yeni takım arkadaşları arıyoruz!",
  "nlpApplication": "Yazılım Mühendisi",
  "nlpApplicationDescription": "Yapay Zeka ve Makine Öğrenmesi departmanımıza yeni takım arkadaşları arıyoruz.",
  "hmiApplication2": "Node.js Geliştirici",
  "hmiApplicationDescription2": "Önyüz ve İnsan Makine Etkileşimi Departmanımıza yeni takım arkadaşları arıyoruz!",
  "saApplication": "Sistem Yöneticisi",
  "saApplicationDescription": "Büyüyen mühendislik ekibimize katılacak Sistem Yöneticisi arıyoruz.",
  "bdiApplication": "Yazılım Mühendisi",
	"bdiApplicationDescription": "Büyüyen mühendislik ekibimize katılacak Yazılım Mühendisi arıyoruz.",
  "eeApplication": "Elektrik Elektronik Mühendisi",
	"eeApplicationDescription": "Büyüyen mühendislik ekibimize katılacak Elektrik-Elektronik Mühendisi arıyoruz.",
  "gameApplication": "Oyun Geliştirici ",
  "gameApplicationDescription": "Büyüyen mühendislik ekibimize katılacak Oyun Geliştirici arıyoruz.",
  "intern":"Stajyer",
  "partTime":"Yarı Zamanlı",
  "detailsButton":"DETAY",
  "seeLess": "Daha az gör",
  "viewAll": "Tümünü gör",


},
"CareerDetail": {
  "softwareEngineer_001GeneralQualificationsTitle":"Genel Nitelikler",
  "softwareEngineer_001RequiredSkillsTitle":"Gerekli Yetenekler",
  "softwareEngineer_001":"Yazılım Mühendisi",
  "intern":"ete technology<0>®</0>’de ilk adım",
  "internFirstDescription":"Kariyerine ilk adımında yanındayız! Eğer öğrenim hayatının son dönemecine girdiysen ve teorik bilgilerini endüstride nasıl uygulayabileceğini keşfetmek istiyorsan ete technology<0>®</0> Staj Programı veya ete technology<0>®</0> Yarı Zamanlı Çalışma Modeli olanaklarından yararlanabilirsin.",
  "internRequiredSkillsTitle":"ete technology<0>®</0> Staj Programı",
	"internRequiredSkills":"ete technology<0>®</0> olarak stajyerlerimizin iş hayatını deneyimlemelerini, mesleki bilgi ve becerilerini geliştirmelerine katkı sağlamayı amaçlıyoruz. \n\nStaj başvuruları her yıl Şubat ve Mart ayları içinde alınmaktadır.  Değerlendirme sonucu olumlu olan adaylar ile iletişime geçilerek, gerekli bilgilendirmeler yapılır. \n\nİş hayatın boyunca yararlanabileceğin deneyimler kazanmak ister misin? Zorunlu stajını ete technology<0>®</0>’de tamamlamak isteyen tüm üniversite öğrencilerini staj programımıza bekliyoruz.",
  "internPositionInformation":"Şu anda başvuramadığınız için üzgünüz, staj başvuruları her yıl 1 Şubat-30 Mart tarihleri arasında yapılmaktadır.",
  "partTime":"ete technology<0>®</0> Yarı Zamanlı Çalışma Modeli",
  "partTimeFirstDescription":"ete technology<0>®</0>’de her yıl birçok üniversite öğrencisi projelerimizde aktif olarak rol alıyor, farklı çalışma konularında iş deneyimi kazanıyor.  Bu kapsamda edineceğiniz yeni iş deneyimi, odaklanacağınız konuları ve profesyonel kariyerinizi nasıl şekillendirmek istediğinizi de belirlemenize yardımcı olacaktır.\n\n\nÜniversitelerin son sınıf öğrencileri Teknopark İzmir Mühendislik Atölye’mizde çeşitli departmanlarda haftada 2 gün çalışabilirler. Başvurular şirket ihtiyaçlarına bağlı olarak ete technology<0>®</0> Kariyer veya LinkedIn hesabımızdan online yayınlanan ilanlar yoluyla kabul edilir.",
  "softwareEngineer_001PositionInformationTitle":"Pozisyon Detayları",
  "softwareEngineer_001FirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Yazılım Mühendisi arıyoruz.",
  "softwareEngineer_001GeneralQualifications":"\n· Üniversitelerin bilgisayar bilimleri, matematik, kriptografi veya ilgili alanlarının 4 yıllık fakültelerinden mezun \n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
  "softwareEngineer_001RequiredSkills":"\n· C, C ++ ve benzeri programlama dillerinde yeterlilik sahibi \n· Pratik kriptografi uygulamaları ile ilgili deneyimli \n· Zero knowledge proofs (Sıfır bilgi kanıtları), bulletproofs, elliptic curve cryptography (eliptik eğri kriptografisi) ve prominent signature şemalarına derinlemesine hakim \n· Güvenlik operasyonları; perimeter defense, data loss protection, insider threat, kill chain analysis, risk assessment ve security metrics kavramları hakkında bilgili \n· Firewall (Güvenlik duvarı), IPS, WAF, NAC, DLP, FIM, PIM, Endpoint Security (Uç Nokta Güvenliği) vb. gibi güvenlik cihazlarının temel işleyişi üzerine tecrübe sahibi \n· Temel TCP/IP ve genel ağ ilkelerine hakim",
  "softwareEngineer_001PositionInformation":"\n· Departman: Büyük Veri ve Enformasyon Bütünleştirme \n· Pozisyon adı: Yazılım Mühendisi \n· Çalışma şekli: Tam zamanlı",
  "softwareEngineer_002GeneralQualificationsTitle":"Genel Nitelikler",
  "softwareEngineer_002RequiredSkillsTitle":"Gerekli Yetenekler",
  "softwareEngineer_002PreferredTitle":"Tercihen",
  "softwareEngineer_002":"Yazılım Mühendisi",
  "softwareEngineer_002PositionInformationTitle":"Pozisyon Detayları",
  "softwareEngineer_002FirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Yazılım Mühendisleri arıyoruz.",
  "softwareEngineer_002GeneralQualifications":"\n· Üniversitelerin bilgisayar bilimleri, matematik veya ilgili başka bir alanda Lisans veya Yüksek Lisans mezunu\n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
  "softwareEngineer_002RequiredSkills":"\n· C, C++ programlama dillerinde deneyim sahibi \n· Nesne Yönelimli (Object Oriented) yazılım geliştirme prensipleri konusunda bilgi sahibi",
  "softwareEngineer_002Preferred":"\n· CUDA ile GPU programlamada deneyim sahibi",
  "softwareEngineer_002PositionInformation":"\n· Departman: Modelleme, Simülasyon ve Oyun \n· Pozisyon adı: Yazılım Mühendisi \n· Çalışma şekli: Tam zamanlı",
  "softwareEngineer_003GeneralQualificationsTitle":"Genel Nitelikler",
  "softwareEngineer_003RequiredSkillsTitle":"Gerekli Yetenekler",
  "softwareEngineer_003PreferredTitle":"Tercihen",
  "softwareEngineer_003":"Web Uygulama Geliştiricisi",
  "softwareEngineer_003PositionInformationTitle":"Pozisyon Detayları",
  "softwareEngineer_003FirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Web Uygulama Geliştiricisi arıyoruz.",
  "softwareEngineer_003GeneralQualifications":"\n· Üniversitelerin bilgisayar bilimleri, yazılım mühendisliği veya ilgili başka bir alanda Lisans veya Yüksek Lisans mezunu\n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
  "softwareEngineer_003RequiredSkills":"\n· JavaScript ve DOM kullanımı üzerine deneyim sahibi\n· React.js kütüphanesi ve prensipleri üzerinde deneyim sahibi\n· Popüler React kütüphanelerinde deneyim sahibi (Flux, Redux)\n· Modern önyüz web uygulamalarında kullanılan derleme süreçlerinde ve araçlarında bilgi sahibi\n· Modern önyüz geliştirme araçlarında bilgi sahibi (Babel, Webpack, NPM)\n· Temiz ve optimize programlama yapabilen",
  "softwareEngineer_003Preferred":"\n· Modern JavaScript kodlama standartlarına yatkın olma\n· Typescript hakkında bilgi sahip olma\n· RESTful API üzerinde bilgi sahibi olma\n· Backend JavaScript hakkında bilgi sahibi olma (NodeJs, Express)\n· Web haritacılık kütüphaneleri üzerinde bilgi sahibi olma (LeafletJS)",
  "softwareEngineer_003PositionInformation":"\n· Departman: Önyüz ve İnsan Makine Etkileşimi \n· Pozisyon adı: Yazılım Mühendisi \n· Çalışma şekli: Tam zamanlı",
  "softwareEngineer_004GeneralQualificationsTitle":"Genel Nitelikler",
  "softwareEngineer_004RequiredSkillsTitle":"Gerekli Yetenekler",
  "softwareEngineer_004PreferredTitle":"Tercihen",
  "softwareEngineer_004":"Yazılım Mühendisi",
  "softwareEngineer_004PositionInformationTitle":"Pozisyon Detayları",
  "softwareEngineer_004FirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Yazılım Mühendisleri arıyoruz.",
  "softwareEngineer_004GeneralQualifications":"\n· Üniversitelerin bilgisayar bilimleri, matematik veya ilgili başka bir alanda Lisans veya Yüksek Lisans mezunu\n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
  "softwareEngineer_004RequiredSkills":"\n· Python ve/veya C/C++ programlama dillerinde deneyim ve iyi bilgi sahibi \n· Nesne Yönelimli (Object Oriented) yazılım kavramlarının iyi anlaşılması\n· İstatistik, makine öğrenimi ve derin öğrenme gibi konularda yeterlilik sahibi\n· Metin sınıflandırması, NER, NLI vb. gibi NLP/NLU görevlerinde bilgi sahibi\n· Keras, PyTorch veya Tensorflow gibi derin öğrenme framework’lerinde deneyim sahibi\n· Akademik araştırma ve NLP/NLU görevlerimiz için çözümleri uygulamaya istekli",
  "softwareEngineer_004Preferred":"\n· Yazıyı konuşmaya çevirme, sesten konuşmacı kimlik tespiti, konuşmacı diarizasyonu gibi Otomatik Konuşma Tanıma (Automatic Speech Recognition) görevlerinde tecrübe sahibi",
  "softwareEngineer_004PositionInformation":"\n· Departman: Yapay Zeka ve Makine Öğrenmesi \n· Pozisyon adı: Yazılım Mühendisi \n· Çalışma şekli: Tam zamanlı",
  "softwareEngineer_005GeneralQualificationsTitle":"Genel Nitelikler",
  "softwareEngineer_005RequiredSkillsTitle":"Gerekli Yetenekler",
  "softwareEngineer_005PreferredTitle":"Tercihen",
  "softwareEngineer_005":"Node.js Geliştirici",
  "softwareEngineer_005PositionInformationTitle":"Pozisyon Detayları",
  "softwareEngineer_005FirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Node.js geliştirici arıyoruz.",
  "softwareEngineer_005GeneralQualifications":"\n· Üniversitelerin bilgisayar bilimleri, yazılım mühendisliği veya ilgili başka bir alanda Lisans veya Yüksek Lisans mezunu\n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
  "softwareEngineer_005RequiredSkills":"\n· JavaScript üzerinde deneyim sahibi\n· Node.js ve kullanılan frameworkleri, kütüphaneleri üzerinde bilgili\n· Basit seviyede HTML5 ve CSS3 bilgisi sahibi\n· Bilgi akışı ve servis kullanımları için arkayüz uygulama arayüzleri tasarlama ve geliştirme yapabilen\n· Temiz ve optimize programlama yapabilen",
  "softwareEngineer_005Preferred":"\n· WebSocket protokolü bilgisi\n· Typescript / ES6 bilgisi\n· RESTful API üzerinde bilgi sahibi olma\n· Veritabanı sistemleri (PostgreSQL, MongoDB) ve Nesne-İlişkisel Eşleme üzerine bilgili",
  "softwareEngineer_005PositionInformation":"\n· Departman: Önyüz ve İnsan Makine Etkileşimi \n· Pozisyon adı: Yazılım Mühendisi \n· Çalışma şekli: Tam zamanlı",
  "sysadmin_001GeneralQualificationsTitle":"Genel Nitelikler",
  "sysadmin_001RequiredSkillsTitle":"Gerekli Yetenekler",
  "sysadmin_001":"Sistem Yöneticisi",
  "sysadmin_001PositionInformationTitle":"Pozisyon Detayları",
  "sysadmin_001FirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Sistem Yöneticisi arıyoruz.",
  "sysadmin_001GeneralQualifications":"\n· Üniversitelerin bilgisayar bilimleri, bilgisayar programcılığı veya ilgili başka bir alanda Lisans veya Ön Lisans mezunu\n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
  "sysadmin_001RequiredSkills":"\n· AWS teknolojilerinde deneyim sahibi (EC2, EBS vb.)\n· Linux sunucularının ve mail sunucularının yönetimi konusunda tecrübeli\n· Sanallaştırma teknolojileri, firewalls ve network konularında temel bilgi sahibi (VMware)\n· Uygulama sunucularının ve web sunucularının güvenliği konusunda tecrübeli",
  "sysadmin_001PositionInformation":"\n· Departman: Datacenter \n· Pozisyon adı: Sistem Yöneticisi \n· Çalışma şekli: Tam zamanlı",
  "softwareEngineer_006GeneralQualificationsTitle":"Genel Nitelikler",
  "softwareEngineer_006RequiredSkillsTitle":"Gerekli Yetenekler",
  "softwareEngineer_006":"Yazılım Mühendisi",
  "softwareEngineer_006PositionInformationTitle":"Pozisyon Detayları",
  "softwareEngineer_006FirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Yazılım Mühendisi arıyoruz.",
  "softwareEngineer_006GeneralQualifications":"\n· Üniversitelerin bilgisayar bilimleri veya ilgili başka bir alanda Lisans veya Yüksek Lisans mezunu\n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
  "softwareEngineer_006RequiredSkills":"\n· Python programlama dilinde bilgi ve deneyim sahibi\n· Nesne yönelimli (Object Oriented) yazılım geliştirme prensipleri konusunda bilgi sahibi\n· HTML, HTTP, Rest API hakkında bilgi sahibi\n· Web Crawling/Scraping araçlarıyla ilgili deneyim sahibi (Scrapy Framework)\n· Git, SVN vb. sürüm kontrol sistemlerinde bilgi sahibi\n· Unix tabanlı işletim sistemlerinde deneyim sahibi",
  "softwareEngineer_006PositionInformation":"\n· Departman: Büyük Veri ve Enformasyon Bütünleştirme\n· Pozisyon adı: Yazılım Mühendisi \n· Çalışma şekli: Tam zamanlı",
  "gameDeveloper_001GeneralQualificationsTitle":"Genel Nitelikler",
  "gameDeveloper_001RequiredSkillsTitle":"Gerekli Yetenekler",
  "gameDeveloper_001":"Oyun Geliştirici",
  "gameDeveloper_001PositionInformationTitle":"Pozisyon Detayları",
  "gameDeveloper_001FirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Oyun Geliştirici arıyoruz.",
  "gameDeveloper_001GeneralQualifications":"\n· Üniversitelerin bilgisayar bilimleri, yazılım mühendisliği veya ilgili başka bir alanda Lisans veya Yüksek Lisans mezunu \n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
  "gameDeveloper_001RequiredSkills":"\n· C++ programlama dilinde iyi derecede bilgi sahibi\n· Unity3D, Unreal Engine teknolojilerinde bilgi sahibi",
  "gameDeveloper_001PositionInformation":"\n· Departman: Modelleme, Simülasyon ve Oyun\n· Pozisyon adı: Yazılım Mühendisi \n· Çalışma şekli: Tam zamanlı",
  "electricalandElectronicsEngineerGeneralQualificationsTitle":"Genel Nitelikler",
	"electricalandElectronicsEngineerRequiredSkillsTitle":"Gerekli Yetenekler",
  "electricalandElectronicsEngineerPreferredTitle":"Tercihen",
  "electricalandElectronicsEngineer":"Elektrik Elektronik Mühendisi",
  "electricalandElectronicsEngineerPositionInformationTitle":"Pozisyon Detayları",
	"electricalandElectronicsEngineerFirstDescription":"\nBüyüyen mühendislik ekibimize katılacak Elektrik-Elektronik Mühendisi arıyoruz.",
	"electricalandElectronicsEngineerGeneralQualifications":"\n· Üniversitelerin Elektrik-Elektronik Mühendisliği, Elektronik ve Haberleşme Mühendisliği veya ilgili başka bir alanda Lisans veya Yüksek Lisans mezunu \n· İyi derecede sözlü ve yazılı İngilizce bilgisine sahip \n· Takım çalışmasına yatkın ve sonuç odaklı \n· 22-28 yaş aralığında bulunan \n· Erkek adaylar için askerlik ile ilişiği olmayan",
	"electricalandElectronicsEngineerRequiredSkills":"\n· Elektronik donanım tasarımına ilgili \n· Mikrodenetleyiciler ve gömülü sistem bileşenlerinde bilgi sahibi \n· Tercihen Altium Designer, EAGLE, Proteus gibi elektronik devre tasarım yazılımlarında deneyimli \n· C/C++ programlama dillerinde bilgi sahibi \n· İletişim protokollerinde bilgi sahibi (CAN, SPI, I2C vb.) \n· Bilgi Kağıtlarını (Datasheets), şematikleri ve bileşenleri takip edebilme",
	"electricalandElectronicsEngineerPreferred":"\n· Donanım bileşenlerini belirlemede bilgi sahibi olma \n· Bileşenler arası iletişim protokollerini belirlemede bilgi sahibi \n· Donanımın şemalarını ve PCB tasarlamada deneyim sahibi \n· Sensör verilerini işleme ve sensörleri test etmede bilgi sahibi \n· Donanım sorunlarına çözüm üretme \n· Prototip ve seri üretim adımlarını takip etme",
  "electricalandElectronicsEngineerPositionInformation":"\n· Departman: Büyük Veri ve Enformasyon Bütünleştirme \n· Pozisyon adı: Elektrik Elektronik Mühendisi \n· Çalışma şekli: Tam zamanlı",
  "apply":"BAŞVUR"

 }
}
